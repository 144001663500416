import {Iso8601, User} from '@hconnect/common/types'
import {
  isStoppageTypeForNewWithCodeOldWithReason,
  isStoppageTypeWithCode,
  isStoppageTypeWithReason
} from '@hconnect/common/utils'
import {Validator} from '@hconnect/common/validators'
import {isEmpty} from 'lodash'
import moment from 'moment'

import {isPartialStoppage} from '../common/utils/eventType'
import {ShiftEventAndUpdateEvent} from '../types/shiftHandover.types'
import {TimeRange} from '../types/timeAndDate.types'

export const shouldNotBeEmptyArray: Validator<ShiftEventAndUpdateEvent> = (
  value: string[] = []
) => {
  return value.length > 0 ? undefined : 'shouldBe.notEmpty'
}

export const requiredEquipmentNumberValidator: Validator<ShiftEventAndUpdateEvent> = (
  value: string,
  item
) => {
  return item.equipment ? undefined : 'shouldBe.notEmpty'
}

const stoppageValidatorWithCodeOldWithReason = (item: Partial<ShiftEventAndUpdateEvent>) => {
  const value = item['stoppageCode'] || item['stoppageReason']
  return value && value.trim().length > 0 ? undefined : 'shouldBe.notEmpty'
}

export const stoppageReasonValidator: Validator<ShiftEventAndUpdateEvent> = (
  value: string,
  item: Partial<ShiftEventAndUpdateEvent>
) => {
  if (
    isPartialStoppage(item) &&
    item.stoppageType &&
    isStoppageTypeForNewWithCodeOldWithReason(item.stoppageType)
  ) {
    return stoppageValidatorWithCodeOldWithReason(item)
  }
  if (isPartialStoppage(item) && item.stoppageType && isStoppageTypeWithReason(item.stoppageType)) {
    return value && value.trim().length > 0 ? undefined : 'shouldBe.notEmpty'
  }
}

export const stoppageCodeValidator: Validator<ShiftEventAndUpdateEvent> = (
  value: string,
  item: Partial<ShiftEventAndUpdateEvent>
) => {
  if (
    isPartialStoppage(item) &&
    item.stoppageType &&
    isStoppageTypeForNewWithCodeOldWithReason(item.stoppageType)
  ) {
    return stoppageValidatorWithCodeOldWithReason(item)
  }
  if (isPartialStoppage(item) && item.stoppageType && isStoppageTypeWithCode(item.stoppageType)) {
    return value && value.trim().length > 0 ? undefined : 'shouldBe.notEmpty'
  }
}

export const validToValidator: Validator<ShiftEventAndUpdateEvent> = (
  date: Iso8601 | undefined,
  item: Partial<ShiftEventAndUpdateEvent>
) => {
  if (date && 'stoppageStart' in item) {
    return moment(date).isAfter(item.stoppageStart) ? undefined : 'shouldBe.dateAfter'
  }
  return undefined
}

export const commonFormScheduleEndValidator: Validator<ShiftEventAndUpdateEvent> = (
  date: TimeRange | undefined,
  item: Partial<ShiftEventAndUpdateEvent>
) => {
  if ('schedule' in item && item.schedule) {
    if (date?.endDate) {
      return moment(date.endDate).isAfter(item.schedule.startDate)
        ? undefined
        : 'shouldBe.dateAfter'
    }
    return 'shouldBe.notEmpty'
  }
  return undefined
}

export const approversValidator: Validator<ShiftEventAndUpdateEvent> = (
  value: User[],
  item: Partial<ShiftEventAndUpdateEvent>
) => {
  if ('parameterChange' in item && item.parameterChange?.needsApproval && isEmpty(value)) {
    return 'shouldBe.notEmpty'
  }
}
