export const sapPm = {
  dialog: {
    equipmentUpdate: {
      header: 'sapPm.dialog.equipmentUpdate.header',
      body: 'sapPm.dialog.equipmentUpdate.body',
      conflictError: 'sapPm.dialog.equipmentUpdate.conflictError',
      validationError: 'sapPm.dialog.equipmentUpdate.validationError',
      unknownError: 'sapPm.dialog.equipmentUpdate.unknownError'
    }
  }
}
