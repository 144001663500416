import {ContentBox} from '@hconnect/common/components/ContentBox'
import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import {Box, LinearProgress, Typography} from '@mui/material'
import {keyframes} from '@mui/system'
import moment, {Moment} from 'moment-timezone'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {isTask} from '../common/utils/eventType'
import {useDraggableTaskContext} from '../hooks/useDraggableTask'
import {getHighlightedEventSx} from '../styles/common'
import {eventRowSx} from '../styles/eventTable'
import {ShiftEvent, Task} from '../types/shiftHandover.types'

const DropHereBox = () => {
  const {t} = useTranslation()
  return (
    <Box
      sx={(theme) => ({
        height: `calc(100% - ${theme.spacing(6)})`,
        margin: theme.spacing(3),
        backgroundColor: '#ebf3fc',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      })}
      data-test-id="drag-here-dialog"
    >
      <MoveToInboxIcon />
      <Typography color="textPrimary">{t('dragAndDrop.title')}</Typography>
    </Box>
  )
}

const scrollToMarker = keyframes`
  0% {
    background-color: rgba(0, 39, 77, 0.04);
  }
  30% {
    background-color: rgba(0, 39, 77, 0.04);
  }
  100% {
    background-color: transparent;
  }
`

const Tasks: React.FC<{
  tasks?: ShiftEvent[]
  markDueDateBefore?: Moment
  scrollToID?: string
  onTaskClick: (task: ShiftEvent) => void
  onDragStart: (task: Task) => void
}> = ({tasks, markDueDateBefore, scrollToID, onTaskClick, onDragStart}) => {
  const {t} = useTranslation()
  const {draggingTask} = useDraggableTaskContext()
  const [activeDrag, setActiveDrag] = React.useState(false)

  return (
    <>
      {tasks?.map((task, i) => (
        <Box
          key={task.id}
          sx={{
            ...(activeDrag &&
              draggingTask?.id === task.id && {
                position: 'relative'
              })
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            onClick={() => {
              onTaskClick(task)
            }}
            draggable={true}
            onDragStart={() => {
              onDragStart(task as Task)
              setTimeout(() => {
                setActiveDrag(true)
              }, 0)
            }}
            onDragEnd={() => {
              setActiveDrag(false)
            }}
            sx={[
              (theme) => ({
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: theme.spacing(2, 3),
                borderTop: `1px solid ${theme.palette.grey[100]}`,
                '&:first-of-type': {
                  borderTop: 'none'
                }
              }),
              eventRowSx(),
              Boolean(task.isHighlighted) && getHighlightedEventSx(),
              scrollToID === task.id && {
                animation: `${scrollToMarker} 3s`
              }
            ]}
            data-test-id={`task-box-item-${i}`}
            id={`task-item-${task.id}`}
          >
            <CardSectionTitle>
              {task.title}{' '}
              {isTask(task) &&
                markDueDateBefore &&
                moment(task.dueDate).isBefore(markDueDateBefore) && (
                  <Typography variant="caption" color="error" sx={{verticalAlign: 1}}>
                    {t('summary.overdue')}
                  </Typography>
                )}
            </CardSectionTitle>
          </Box>
          <Box
            sx={[
              activeDrag &&
                draggingTask?.id === task.id && {
                  position: 'absolute',
                  top: '0',
                  height: '50px',
                  width: '100%',
                  zIndex: 1000,
                  background: '#ebf3fc'
                }
            ]}
          />
        </Box>
      ))}
    </>
  )
}

export const TasksList: React.FC<{
  'data-test-id'?: string
  title: string
  tasks?: ShiftEvent[]
  isLoading?: boolean
  options?: React.ReactNode
  markDueDateBefore?: Moment
  onTaskClick: (task: ShiftEvent) => void
  scrollToID?: string
  onDragStart: (task: Task) => void
  isDragOver: boolean
}> = (props) => {
  useEffect(() => {
    document
      ?.getElementById(`task-item-${props.scrollToID}`)
      ?.scrollIntoView({behavior: 'smooth', block: 'start'})
  }, [props.scrollToID])

  return (
    <ContentBox
      title={props.title}
      afterTitle={props.options}
      mode="max100PercentOfParentHeight"
      data-test-id={props['data-test-id']}
      bodySx={{height: '100%'}}
      sx={{height: '100%'}}
    >
      {props.isDragOver ? (
        <DropHereBox />
      ) : (
        <>
          {props.isLoading && <LinearProgress />}
          <Tasks
            tasks={props.tasks}
            markDueDateBefore={props.markDueDateBefore}
            scrollToID={props.scrollToID}
            onTaskClick={props.onTaskClick}
            onDragStart={props.onDragStart}
          />
        </>
      )}
    </ContentBox>
  )
}
