import {AttachmentFile} from '@hconnect/common/types'
import moment, {Moment} from 'moment-timezone'

import {DateUTC} from './atomic.types'

export enum InstructionStatus {
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export enum InstructionType {
  LINK = 'LINK',
  PDF = 'PDF'
}

export type InstructionDto = {
  id: string
  title: string
  processStage: string
  validFrom: DateUTC
  validTo?: DateUTC
  attachment?: string
  attachmentFileName?: string
  link?: string
  createdAt: DateUTC
  updatedAt: DateUTC
}

export type InstructionsDto = InstructionDto[]

export class InstructionVM {
  public id: string
  public title: string
  public processStage: string
  public validFrom: Moment
  public status: InstructionStatus
  public type: InstructionType
  public validTo?: Moment
  public attachment?: string
  public attachmentFileName?: string
  public link?: string
  public createdAt: Moment
  public updatedAt: Moment
  public deleteDayCounter?: number

  constructor(data: InstructionDto, timezone: string) {
    this.id = data.id
    this.title = data.title
    this.processStage = data.processStage
    this.validFrom = moment.utc(data.validFrom).tz(timezone)
    this.validTo = moment.utc(data.validTo).tz(timezone)
    this.attachment = data.attachment
    this.attachmentFileName = data.attachmentFileName
    this.link = data.link
    this.createdAt = moment.utc(data.createdAt).tz(timezone)
    this.updatedAt = moment.utc(data.updatedAt).tz(timezone)

    if (moment().isBefore(data.validFrom)) {
      this.status = InstructionStatus.UPCOMING
    } else if (moment().isAfter(data.validTo)) {
      this.status = InstructionStatus.EXPIRED
      this.deleteDayCounter = moment
        .utc(data.validTo)
        .tz(timezone)
        .add(31, 'days')
        .diff(moment().tz(timezone), 'days')
    } else {
      this.status = InstructionStatus.ACTIVE
    }
    this.type = data.attachment ? InstructionType.PDF : InstructionType.LINK
  }
}

export type DocumentUpdate = InstructionVM & {
  newAttachment?: AttachmentFile
}

export type PossibleErrors = keyof DocumentUpdate

export type InstructionPayload = {
  title: string
  processStage: string
  validFrom: DateUTC
  validTo?: DateUTC
  attachment?: AttachmentFile
  link?: string
}

export type DocumentSearchCriteria = {
  freeText?: string
  processStage?: string
  status?: InstructionStatus
}
