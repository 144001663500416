import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {OverdueLabel} from '@hconnect/common/components/eventProperties/OverdueLabel'
import {EventTypeLabel} from '@hconnect/common/components/shiftEventLabels'
import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {Box, Typography, SxProps, Theme} from '@mui/material'
import React from 'react'

import {isTask} from '../common/utils/eventType'
import {CommentCounter} from '../components/common/CommentCounter'
import {PriorityIcon} from '../components/common/PriorityIcon'
import {useConfig} from '../hooks/useConfig'
import {getHighlightedEventSx} from '../styles/common'
import {eventRowSx} from '../styles/eventTable'
import {ShiftEvent} from '../types/shiftHandover.types'

export type Props = {
  sx?: SxProps<Theme>
  'data-test-id'?: string
  item: ShiftEvent
  onClick: (item: ShiftEvent) => void
}

export const EventSummaryComponent: React.FC<Props> = ({
  item,
  onClick,
  'data-test-id': dataTestId,
  sx
}) => {
  const config = useConfig()
  return (
    <Box
      sx={mergeSxs(
        (theme: Theme) => ({
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          minHeight: theme.spacing(6),
          padding: theme.spacing(0, 2, 0, 2)
        }),
        [eventRowSx(), Boolean(item.isHighlighted) && getHighlightedEventSx()],
        sx
      )}
      onClick={() => onClick(item)}
      data-test-id={dataTestId}
    >
      <Box display={'flex'} flex={1} alignItems="start" pt={0.5} pb={0.5}>
        <Box display={'flex'} alignItems="start" flex={1}>
          <Box width={28} display={'flex'} flexShrink={0} justifyContent={'flex-end'} pr={0.25}>
            {'priority' in item && item.priority && item.priority <= 2 && (
              <PriorityIcon priority={item.priority} />
            )}
          </Box>
          <EventTypeLabel
            eventType={item.eventType}
            iconOnly
            sx={{
              flexShrink: 0,
              pr: 1,
              minHeight: 24
            }}
          />
          <Typography
            sx={[
              {
                flexGrow: 1,
                overflow: 'hidden',
                overflowWrap: 'anywhere'
              }
            ]}
            variant={'h5'}
          >
            {item.title}{' '}
            {isTask(item) && (
              <OverdueLabel
                status={item.status}
                dueDate={item.dueDate}
                plantNow={config.plantNow()}
              />
            )}
          </Typography>
        </Box>
        <EventStatusLabel
          status={item.status}
          sx={{
            flexShrink: 0,
            height: 20
          }}
        />

        <CommentCounter id={item.id} count={item.commentCount} sx={{ml: 1.5, minWidth: 44}} />
      </Box>
    </Box>
  )
}
