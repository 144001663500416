export function combineSearchParamsToQuery(...searchParams: URLSearchParams[]) {
  const allParams = new URLSearchParams()
  searchParams.forEach((searchParam) => {
    for (const [key, val] of searchParam.entries()) {
      allParams.append(key, val)
    }
  })
  return allParams
}

export function combineSearchParamsToQueryString(...searchParams: URLSearchParams[]) {
  return combineSearchParamsToQuery(...searchParams).toString()
}

export const sameSearchParamsByKeys = (
  searchParams1: URLSearchParams,
  searchParams2: URLSearchParams,
  keys: string[]
) => {
  for (const key of keys) {
    const value1 = searchParams1.get(key)
    const value2 = searchParams2.get(key)

    if (value1 !== value2) {
      return false
    }
  }

  return true
}

export const pickKeysFromSearchParams = (
  searchParams: URLSearchParams,
  keys: string[]
): URLSearchParams => {
  const pickedParams = new URLSearchParams()

  keys.forEach((key) => {
    const value = searchParams.get(key)
    if (value !== null) {
      pickedParams.set(key, value)
    }
  })

  return pickedParams
}

export function filterSearchParams(
  searchParams: URLSearchParams,
  keysToRemove: string[]
): URLSearchParams {
  const filteredParams = new URLSearchParams()

  for (const [key, value] of searchParams.entries()) {
    if (!keysToRemove.includes(key)) {
      filteredParams.append(key, value)
    }
  }

  return filteredParams
}
