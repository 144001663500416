import {Env} from '@hconnect/common/hproduce'
import {PageTitle, Shell, ShellFooter, PageContainer} from '@hconnect/uikit/src/lib2'
import LogoutIcon from '@mui/icons-material/Logout'
import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getButtonOutlineWhite} from '../styles/common'

export type SimpleErrorPageProps = {
  message?: string
  logout?: () => void
  withRedirectButton?: boolean
  env?: Env
  children?: React.ReactNode
}

export const SimpleErrorPage: React.FC<SimpleErrorPageProps> = ({
  message,
  logout,
  withRedirectButton,
  env
}) => {
  const {t} = useTranslation()
  return (
    <Shell isResponsive footer={<ShellFooter label={t('footer.poweredBy')} />}>
      <PageContainer>
        <PageTitle textAlign={'center'}>{t('simpleErrorPage.title', 'Error')}</PageTitle>
        <Box alignItems="center" display="flex" flexDirection="column" gap={2}>
          <Box width={'100%'} component={'code'} textAlign={'center'} sx={{wordWrap: 'break-word'}}>
            {message}
          </Box>
          {withRedirectButton && (
            <Button
              data-test-id="redirect-on-error-button"
              onClick={() =>
                window.location.replace(
                  `https://${env === 'prod' ? '' : `${env}.`}hproduce.digital/Cockpit`
                )
              }
              variant="text"
              sx={getButtonOutlineWhite}
            >
              {t('error.redirectToEntryPoint')}
            </Button>
          )}
          {logout && (
            <Button
              startIcon={<LogoutIcon />}
              sx={getButtonOutlineWhite}
              onClick={logout}
              variant={'text'}
            >
              {t('logout')}
            </Button>
          )}
        </Box>
      </PageContainer>
    </Shell>
  )
}
