import {DataTable, Column, PaginationOptions} from '@hconnect/uikit/src/lib2'
import {SxProps, Theme, Box} from '@mui/material'
import React, {ElementType} from 'react'
import {useTranslation} from 'react-i18next'

import {getKeyByValue} from '../common/utils/objectUtils'
import {ColumnKeys} from '../components/tableHelpers'
import {getHighlightedEventCellSx, getHighlightedEventSx, markEvent} from '../styles/common'
import {eventRowSx} from '../styles/eventTable'
import {ShiftEvent, SortBy, SortOrder} from '../types/shiftHandover.types'
import {getSortDirection, getSortOrder} from '../utils'

export type SortingOptions = {
  sortBy?: SortBy
  sortOrder?: SortOrder
  applySort: (sortBy?: SortBy, sortOrder?: SortOrder) => void
}

export type Props = {
  columns: Column<ShiftEvent>[]
  events?: ShiftEvent[]
  isLoading?: boolean
  onRowClick(item: ShiftEvent, i: number): void
  selectedItemId?: string
  paginationOptions?: PaginationOptions
  rowFooterTemplate?: ElementType<{rowData: ShiftEvent}>
  sortingOptions: SortingOptions
}

export const rowSx = (item: ShiftEvent, selected: boolean): SxProps<Theme> => {
  const isHighlighted = Boolean(item.isHighlighted)
  const isNotApproved = ['waitingForApproval', 'rejected'].includes(item.status)

  return [
    eventRowSx(selected),
    isHighlighted &&
      !isNotApproved && {...getHighlightedEventSx(selected), ...getHighlightedEventCellSx()},
    isNotApproved && markEvent
  ]
}

const columnSortKeyMapping: {[key in ColumnKeys]: SortBy} = {
  [ColumnKeys.EventType]: SortBy.EventType,
  [ColumnKeys.Title]: SortBy.Title,
  [ColumnKeys.EquipmentNumber]: SortBy.EquipmentNo,
  [ColumnKeys.Category]: SortBy.Category,
  [ColumnKeys.ProcessStage]: SortBy.ProcessStage,
  [ColumnKeys.Created]: SortBy.Created,
  [ColumnKeys.MaintenanceNotification]: SortBy.MaintenanceNotificationId,
  [ColumnKeys.Status]: SortBy.Status
}

export const EventsTable: React.FC<Props> = (props) => {
  const selectedItemId = props.selectedItemId

  const {t} = useTranslation()

  const currentSortDirection = getSortDirection(props.sortingOptions.sortOrder)
  const currentSortBy = props.sortingOptions.sortBy
    ? getKeyByValue<ColumnKeys, SortBy>(columnSortKeyMapping, props.sortingOptions.sortBy)
    : undefined

  return (
    <Box
      data-test-id="events-table"
      display="flex"
      flexDirection="column"
      flex={1}
      overflow="hidden"
      justifyContent="space-between"
    >
      <DataTable<ShiftEvent>
        columns={props.columns}
        data={props.events || []}
        onSort={(event, columnKey) => {
          const sortBy: SortBy = columnSortKeyMapping[columnKey]
          const sortOrder = getSortOrder(
            currentSortBy === (columnKey as ColumnKeys) && currentSortDirection === 'asc'
              ? 'desc'
              : 'asc'
          )
          props.sortingOptions.applySort(sortBy, sortOrder)
        }}
        sortedBy={currentSortBy}
        sortDirection={currentSortDirection}
        rowSx={(item) => rowSx(item, selectedItemId === item.id)}
        emptyMessage={(!props.isLoading && t('eventsTable.noData')) ?? ''}
        onRowClick={(keyEvent: React.MouseEvent, clickedItem: ShiftEvent, i: number) =>
          props.onRowClick(clickedItem, i)
        }
        paginationOptions={props.paginationOptions}
        rowFooterTemplate={props.rowFooterTemplate}
        loading={props.isLoading}
        data-test-id="events-table-container"
      />
    </Box>
  )
}
