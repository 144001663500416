import {ToggleButtonGroup, Typography, ToggleButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  isoWeekDays,
  IsoWeekDay,
  getLocaleDependentWeekDays,
  sortWeekDaysLocaleDependent
} from '../../../common'

interface WeekDaySelectProps {
  selectedDays: IsoWeekDay[]
  onChange: (next: IsoWeekDay[]) => void
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void
  isDisabled?: boolean
  className?: string
  weekDayLabel?: (day: IsoWeekDay) => string
}

export const WeekDaySelect: React.FC<WeekDaySelectProps> = ({
  selectedDays,
  onChange,
  onBlur,
  isDisabled = false,
  className,
  weekDayLabel
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const weekDays = getLocaleDependentWeekDays(language)
  const weekDayLabelFunc = weekDayLabel ?? ((day: IsoWeekDay) => t(`weekdaysShort.${day}`))

  if (isDisabled) {
    return (
      <Typography variant="h4" color="textPrimary" data-test-id="week_day_select_disabled">
        {sortWeekDaysLocaleDependent(language, selectedDays)
          .map((day) => weekDayLabelFunc(day))
          .join(', ')}
      </Typography>
    )
  }

  return (
    <ToggleButtonGroup
      data-test-id="week-day-select"
      sx={{
        '& .MuiToggleButton-root': {
          color: 'primary.light',
          textTransform: 'none',
          backgroundColor: 'common.white'
        },
        '& .MuiToggleButton-root.Mui-selected': {
          backgroundColor: 'primary.dark',
          color: 'primary.contrastText'
        }
      }}
      className={className}
      onChange={(_, next) =>
        next &&
        onChange(
          (next as IsoWeekDay[]).sort((a: IsoWeekDay, b: IsoWeekDay) => {
            return isoWeekDays.indexOf(a) - isoWeekDays.indexOf(b)
          })
        )
      }
      value={selectedDays}
      onBlur={(e) => e.relatedTarget === null && onBlur?.(e)}
    >
      {weekDays.map((day) => (
        <ToggleButton
          key={day}
          value={day}
          color="primary"
          data-test-id={`weekday-select-day-${day}`}
        >
          {weekDayLabelFunc(day)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
