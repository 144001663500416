import {DateRange, Shift} from '@hconnect/common/types'
import {isString} from 'lodash'
import {Moment} from 'moment-timezone'

import {getQuickSelectFilter, QuickSelectSlots} from '../common/utils/filterParameter'
import {Iso8601} from '../types/shiftHandover.types'
import {TimeRange} from '../types/timeAndDate.types'

export const toTimeRange = ([startDate, endDate]: [start: Moment, end: Moment]): TimeRange => ({
  startDate: startDate.toISOString(),
  endDate: endDate.toISOString()
})
export const toDateRange = ([startDate, endDate]: [start: Moment, end: Moment]): DateRange => ({
  startDate,
  endDate
})

export const queryRangeToTimeRange = (
  queryRange: DateRange | QuickSelectSlots,
  shifts: Shift[],
  currentTime: Moment
): {startDate: Iso8601; endDate: Iso8601} => {
  if (isString(queryRange)) {
    const [startDate, endDate] = getQuickSelectFilter(queryRange, shifts, currentTime)
    return {startDate: startDate.toISOString(), endDate: endDate.toISOString()}
  }
  return toTimeRange([queryRange.startDate, queryRange.endDate])
}
