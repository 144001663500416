import {ContentBox} from '@hconnect/common/components/ContentBox'
import {Grid, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CloseButton} from '../components/common/CloseButton'

export type Props = {
  itemId: string
  doClose(): void
}

export const EventNotFound: React.FC<Props> = (props) => {
  const {t} = useTranslation()

  return (
    <ContentBox
      data-test-id="events-details"
      mode="max100PercentOfParentHeight"
      bodyWithPadding
      afterTitle={<CloseButton onClick={() => props.doClose()} />}
    >
      <Grid container justifyContent="center" alignItems="center" sx={{height: '150px'}}>
        <Typography
          variant={'h2'}
          style={{textTransform: 'none'}}
          data-test-id="event-details-title"
        >
          {t('shiftEvent.notFound')}
        </Typography>
      </Grid>
    </ContentBox>
  )
}
