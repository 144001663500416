import {InforPriority} from '../types/maintenanceNotification.types'
import type {ShiftEvent} from '../types/shiftHandover.types'

// Maps priority values from ShiftHandover API to Infor API for use in CreateInforMaintenanceNotification
// mapping is needed because the priorities are different in both APIs
const shiftEventToInforPriorityMapping: {[key: number]: InforPriority} = {
  1: InforPriority.Immediate,
  2: InforPriority.WithinADay,
  3: InforPriority.WithinOneWeek,
  4: InforPriority.WithinOneMonth,
  5: InforPriority.NextPmStop,
  6: InforPriority.AnnualShutdown,
  9: InforPriority.BufferWork
}

export const shiftEventToInforPriorityMapper = (
  shiftEventPriority: ShiftEvent['priority'] | undefined
): InforPriority | undefined => {
  return shiftEventPriority !== undefined
    ? shiftEventToInforPriorityMapping[shiftEventPriority]
    : undefined
}

export const inforPriorityToShiftEventMapper = (
  inforPriority: InforPriority | undefined
): ShiftEvent['priority'] | undefined => {
  const entry = Object.entries(shiftEventToInforPriorityMapping).find(
    ([, value]) => value === inforPriority
  )
  return entry ? parseInt(entry[0], 10) : undefined
}
