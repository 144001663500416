import {Moment} from 'moment'

import {Iso8601} from './atomic.types'

export type CreateMaintenanceNotification = {
  sourceEventId: string
  workCenterId?: string
  plannerGroupId?: string
}

export enum SapStatusCode {
  ON_GOING = 'OSNO', // Outstanding notification
  PENDING = 'NOPR', // Notification in process
  DONE = 'NOCO', // Notification completed
  SUBMITTED = 'Submitted',
  CREATED = 'ORAS' // Order assigned
}

export type SapDataDto = {
  sapStatusCode?: SapStatusCode
  sapMaintenanceNotificationId?: string
  lastMaintenanceNotificationCreationStart?: Iso8601
}
export type SapData = {
  sapStatusCode?: SapStatusCode
  sapMaintenanceNotificationId?: string
  lastMaintenanceNotificationCreationStart?: Moment
}

export enum InforDepartmentCode {
  Electrical = 'electrical',
  Garage = 'garage',
  Hse = 'hse',
  Laboratory = 'laboratory',
  Logistic = 'logistic',
  Maintenance = 'maintenance',
  Mcc = 'mcc',
  Mechanical = 'mechanical',
  Process = 'process',
  Production = 'production',
  Project = 'project',
  Quarry = 'quarry',
  Warehouse = 'warehouse'
}

export enum InforPriority {
  Immediate = 'immediate',
  WithinADay = 'withinADay',
  WithinOneWeek = 'withinOneWeek',
  WithinOneMonth = 'withinOneMonth',
  NextPmStop = 'nextPmStop',
  AnnualShutdown = 'annualShutdown',
  BufferWork = 'bufferWork'
}

export enum InforProblemCode {
  Alarm = 'alarm',
  BeltConveyorFault = 'beltConveyorFault',
  ElevatorFault = 'elevatorFault',
  PlannedRepairWork = 'plannedRepairWork',
  CompressedAirFault = 'compressedAirFault',
  DedustingFaultyDPFT = 'dedustingFaultyDPFT',
  FillingFailure = 'fillingFailure',
  FluxoPumpFailure = 'fluxoPumpFailure',
  GateValveFault = 'gateValveFault',
  HighTemperatureHTMP = 'highTemperatureHTMP',
  ExcessiveVibrationHv = 'excessiveVibrationHv',
  EquipmentJammed = 'equipmentJammed',
  KilnInDefault = 'kilnInDefault',
  Leakage = 'leakage',
  Miscellaneous = 'miscellaneous',
  PanConvDefault = 'panConvDefault',
  LinerPlateFault = 'linerPlateFault',
  RoutineInspectionMonitoring = 'routineInspectionMonitoring',
  RoutineLubrication = 'routineLubrication',
  RoutinePreventativeMaintenance = 'routinePreventativeMaintenance',
  ReclaimerDefault = 'reclaimerDefault',
  RefractoryFault = 'refractoryFault',
  RollerMillFault = 'rollerMillFault',
  RunningFailure = 'runningFailure',
  EquipmentSeizure = 'equipmentSeizure',
  StartingFailure = 'startingFailure',
  EquipmentTripped = 'equipmentTripped'
}

export type CreateInforWorkOrderRequest = {
  eventId: string
  equipmentTechnicalId: string
  priority: InforPriority
  problemCode: InforProblemCode
  departmentCode: InforDepartmentCode
}
