import {shouldNotBeEmpty, Validator} from '@hconnect/common/validators'
import moment from 'moment'

import {Iso8601} from '../types/atomic.types'
import {InstructionType, DocumentUpdate} from '../types/documents.types'

const URL_REGEX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

export const validToValidator: Validator<DocumentUpdate> = (
  date: Iso8601 | undefined,
  item: Partial<DocumentUpdate>
) => {
  if (date) {
    return moment(date).isAfter(item.validFrom) ? undefined : 'shouldBe.dateAfter'
  }
  return undefined
}

export const shouldNotBeEmptyLinkValidator: Validator<DocumentUpdate> = (
  value: string,
  item: Partial<DocumentUpdate>
) => {
  if (item.type === InstructionType.LINK) {
    const emptyError = shouldNotBeEmpty()(value, item)
    if (emptyError) {
      return emptyError
    }
    if (!URL_REGEX.test(value)) {
      return 'shouldBe.validUrl'
    }
  }
}

export const shouldNotBeEmptyAttachmentValidator: Validator<DocumentUpdate> = (
  value: string,
  item: Partial<DocumentUpdate>
) => {
  if (item.type === InstructionType.PDF && !item.attachment) {
    return shouldNotBeEmpty()(value, item)
  }
}
