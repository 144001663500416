// alias to clarify when we use Iso8601 strings (fully qualified or in UTC)
export type Iso8601 = string

export type TimeZone = string

// HH:mm:ss or HH:mm:ss.fff in 24h format, i.e. 06:00:00, 23:15:00 or 05:59:59.999
export type DayTime = string

// alias to clarify we expect a valid Uuid (at v4 of the spec)
export type Uuid = string

export type DateUTC = Iso8601

/*
  isoWeekDays always start on Mondays in any locale - see https://momentjs.com/docs/#/get-set/iso-weekday/

  warning moment().isoWeekday() starts with 1 not with 0 (monday 1, ... sunday 7) so use
    isoWeekDays[moment().isoWeekday() - 1] or
    isoWeekDays.indexOf('monday') + 1
*/
export const isoWeekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
] as const
export type IsoWeekDay = (typeof isoWeekDays)[number]
