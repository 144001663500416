import {FilterList as FilterIcon} from '@mui/icons-material'
import {Badge, Button, Box, useMediaQuery, Theme} from '@mui/material'
import {isEqual, omit} from 'lodash'
import React, {ReactNode, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {FilterOptions, sortingFilterParams} from '../common/utils/filterParameter'
import {Filter} from '../components/filter/Filter'
import {filterSxStyles} from '../styles/filter'

type Props<T> = {
  isCollapsible: boolean
  activeSettings: FilterOptions
  onChange: (next: FilterOptions) => void
  openMobileFilter: (state: boolean) => void
  isMobileFilterOpen: boolean
  hideDateFilter?: boolean
  options: T[]
  defaultFilters?: string[]
  additionalActions?: ReactNode
  showDescriptions?: (show: boolean) => void
}

export function EventsFilterBar<T extends string>(props: Props<T>) {
  const {t} = useTranslation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [settings, setSettings] = useState<FilterOptions>(props.activeSettings)

  // sync with the given props
  useMemo(() => setSettings(props.activeSettings), [props.activeSettings])

  if (props.isCollapsible && !props.isMobileFilterOpen) {
    const filters = omit(settings, sortingFilterParams)
    const totalFilters: number = Object.keys(filters).reduce((acc, curr) => {
      if (Array.isArray(settings[curr])) {
        acc += settings[curr].length
      } else if (settings[curr]) {
        acc += 1
      }
      return acc
    }, 0)
    return (
      <Box data-test-id={'events-filter-bar'} sx={filterSxStyles.filterBar}>
        <Box sx={filterSxStyles.filterButton}>
          <Badge
            badgeContent={totalFilters}
            color="primary"
            data-test-id="events-filters-badge"
            overlap="circular"
          >
            <Button
              variant="text"
              startIcon={!isMobile && <FilterIcon />}
              sx={filterSxStyles.filterSearchButton}
              onClick={() => {
                props.openMobileFilter(true)
              }}
              data-test-id="events-filter-bar-toggle-button"
              id="events-filter-bar-toggle-button"
            >
              {isMobile ? <FilterIcon /> : t('shiftEvent.action.filter')}
            </Button>
          </Badge>
        </Box>
      </Box>
    )
  }

  const update = (next: FilterOptions) => {
    if (isEqual(settings, next)) {
      return
    }

    if (props.isCollapsible) {
      // in the collapsible mode, the user has to press a button before applying the new filter settings
      setSettings(next)
    } else {
      props.onChange(next)
    }
  }

  return (
    <Box
      data-test-id="events-filter-bar-box"
      sx={props.isMobileFilterOpen ? filterSxStyles.filterLayover : filterSxStyles.filterBar}
      display={'flex'}
      flexDirection={'column'}
    >
      {props.isCollapsible && (
        <Box sx={filterSxStyles.filterButton} mr={1} textAlign={'right'}>
          <Button
            data-test-id="events-filter-bar-cancel-button"
            id="events-filter-bar-cancel-button"
            variant={'contained'}
            onClick={() => {
              props.openMobileFilter(false)
              setSettings(props.activeSettings)
            }}
          >
            {t('action.cancel')}
          </Button>
        </Box>
      )}
      <Filter
        data-test-id={'events-filter-bar-filter'}
        onChange={update}
        settings={settings}
        options={props.options}
        hideDateFilter={props.hideDateFilter}
        defaultFilters={props.defaultFilters}
        isCollapsible={props.isCollapsible}
        applyFilter={(settings) => {
          props.openMobileFilter(false)
          props.onChange(settings)
        }}
        additionalActions={props.additionalActions}
        showDescriptions={props.showDescriptions}
      />
    </Box>
  )
}
