import {type Category} from '@hconnect/common/types'

import {InforDepartmentCode} from '../types/maintenanceNotification.types'

export function categoryToInforDepartmentCodeMapper(
  category: Category | undefined
): InforDepartmentCode | undefined {
  if (Object.values(InforDepartmentCode).includes(category as InforDepartmentCode)) {
    return category as InforDepartmentCode
  }
  return undefined
}
