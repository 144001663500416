import {
  Shell,
  ShellFooter,
  PageContainer,
  PageTitle,
  customThemeConstants
} from '@hconnect/uikit/src/lib2'
import {Box, Typography} from '@mui/material'
import {AxiosError} from 'axios'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {isAxiosError} from '../common/utils/errorhelper'
import {HeaderContent} from '../containers/header/HeaderContent'

export type Props = {
  error?: Error | AxiosError
}

const Pre = (props: {children?: React.ReactNode}) => (
  <Box component={'code'} width={'100%'} sx={{wordWrap: 'break-word'}} {...props} />
)

export const ErrorPage: React.FC<Props> = ({error}) => {
  const {t} = useTranslation()
  return (
    <Shell
      header={<HeaderContent />}
      isResponsive
      footer={<ShellFooter label={t('footer.poweredBy')} />}
    >
      <PageContainer>
        {error && (
          <>
            <PageTitle data-test-id="error-name">{error.name}</PageTitle>
            <Box
              display={'flex'}
              gap={2}
              flexDirection={'column'}
              sx={{p: customThemeConstants().defaultGridSpacing}}
            >
              <Typography variant={'h2'} data-test-id="error-message">
                {error.message}
              </Typography>
              {!isAxiosError(error) && <Pre data-test-id="error-stack">{error.stack}</Pre>}
              {isAxiosError(error) && (
                <>
                  <Pre data-test-id="axios-error-code">{error.code}</Pre>
                  <Pre data-test-id="axios-request-url">
                    {error.config.method?.toUpperCase()} {error.config.url}
                  </Pre>
                  {error.response?.data && (
                    <Pre data-test-id="axios-request-data">
                      {JSON.stringify(error.response?.data)}
                    </Pre>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </PageContainer>
    </Shell>
  )
}
