import {
  CustomDailyRepetition,
  CustomTaskRepetition,
  CustomWeeklyRepetition,
  TaskRepetition
} from '@hconnect/common/types'

export type RepetitionInputsSettings = {
  disableCheckbox: boolean
  disableSelect: boolean
  checkboxMessage?: string
}

export function isCustomDailyRepetition(
  repetitionInfo: TaskRepetition
): repetitionInfo is CustomDailyRepetition {
  return repetitionInfo.repetition === 'daily' && 'repeatEvery' in repetitionInfo
}

export function isCustomWeeklyRepetition(
  repetitionInfo: TaskRepetition
): repetitionInfo is CustomWeeklyRepetition {
  return (
    repetitionInfo.repetition === 'weekly' &&
    'repeatEvery' in repetitionInfo &&
    'weekDays' in repetitionInfo
  )
}

/**
 * currently there is no solid way of determine that it is a custom setting
 * other then checking for custom parameters
 */
export function isCustomRepetition(
  repetitionInfo: TaskRepetition
): repetitionInfo is CustomTaskRepetition {
  return (
    isCustomDailyRepetition(repetitionInfo) ||
    isCustomWeeklyRepetition(repetitionInfo) ||
    'endDate' in repetitionInfo ||
    'endsAfterRepetitions' in repetitionInfo
  )
}
