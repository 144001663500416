import {shouldNotBeEmpty, Validator} from '@hconnect/common/validators'

const MAX_STRING_LENGTH = 255

export const maxStringLengthValidator =
  <T>(): Validator<T> =>
  (value: string | undefined, item: Partial<T>) => {
    const isEmptyError = shouldNotBeEmpty<T>()(value, item)
    if (isEmptyError) {
      return isEmptyError
    }
    if (value && value.length > MAX_STRING_LENGTH) {
      return 'shouldBe.shorter'
    }
  }
