import {FlexPage} from '@hconnect/common/components/FlexPage'
import {ShiftInput} from '@hconnect/common/types'
import {shouldNotBeEmptyString, validateAll, ValidatorConfig} from '@hconnect/common/validators'
import {Add, Check, Close} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Button, Dialog, Grid, Paper, Typography, Box} from '@mui/material'
import {cloneDeep} from 'lodash'
import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'

import {useShiftsCreate} from '../hooks/api/useShiftsCreate'
import {useConfig} from '../hooks/useConfig'
import {ManageShiftTable} from '../layouts/ManageShiftsTable'
import {ShiftEventAndUpdateEvent} from '../types/shiftHandover.types'
import {shouldNotBeEmptyArray} from '../validators/shiftEvent.validators'

const defaultRow: ShiftInput = {
  name: '',
  start: '00:00',
  end: '00:00',
  weekDays: []
}

const validatorConfig: ValidatorConfig<ShiftEventAndUpdateEvent> = new Map([
  ['name', shouldNotBeEmptyString<ShiftEventAndUpdateEvent>()],
  ['start', shouldNotBeEmptyString<ShiftEventAndUpdateEvent>()],
  ['end', shouldNotBeEmptyString<ShiftEventAndUpdateEvent>()],
  ['weekDays', shouldNotBeEmptyArray]
])

type SubmitState = {
  submit: boolean
  success?: boolean
  error?: string[]
}

const initialSubmitState: SubmitState = {submit: false}

export const ManageShiftPage = () => {
  const {t} = useTranslation()
  const {shifts} = useConfig()
  const {plantId} = useParams<{plantId: string}>()
  if (!plantId) throw new Error('plantId is required')
  const {data: shiftData, error, mutate: createShifts} = useShiftsCreate(plantId)
  const [isEditMode, setEditMode] = useState<boolean>(!shifts.length)
  const data = shiftData || (shifts.length > 0 && shifts) || [{...defaultRow}]
  const [shiftList, setShiftList] = useState<ShiftInput[]>(data)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitState, setSubmitState] = useState<SubmitState>(initialSubmitState)

  const errorMapping = {
    'shouldBe.notEmpty': t('manageShifts.error.notEmpty')
  }

  const keyErrorMapping = {
    name: t('manageShifts.label.shiftName'),
    start: t('manageShifts.label.start'),
    end: t('manageShifts.label.end'),
    weekDays: t('manageShifts.label.weekDays')
  }

  const getErrorList = (validationError): string[] => {
    return validationError.reduce((acc, currErr) => {
      const iterator = currErr.keys()
      let key = iterator.next().value
      while (key) {
        const value = currErr.get(key)
        const errorMsg = `${keyErrorMapping[key]} ${errorMapping[value]}`
        if (acc.indexOf(errorMsg) === -1) {
          acc.push(errorMsg)
        }
        key = iterator.next().value
      }
      return acc
    }, [])
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const validationError = shiftList.reduce((acc, curr) => {
      const err = validateAll<ShiftEventAndUpdateEvent>(
        validatorConfig,
        curr as Partial<ShiftEventAndUpdateEvent>
      )
      if (err.size !== 0) acc.push(err as never)
      return acc
    }, [])
    if (validationError.length === 0) {
      setLoading(true)
      createShifts(shiftList)
    } else {
      const errorList = getErrorList(validationError)
      setSubmitState({submit: true, error: errorList})
    }
  }

  useEffect(() => {
    if (shiftData?.length) {
      setEditMode(false)
      setLoading(false)
      setSubmitState({submit: true, success: true})
      return
    }
    if (!error) return
    if (error?.response?.status === 400) {
      setLoading(false)
      setSubmitState({submit: true, error: [t('manageShifts.error.overlappingShifts')]})
    } else if (error?.response?.status === 409) {
      setLoading(false)
      setSubmitState({submit: true, error: [t('manageShifts.error.shiftSystemDefined')]})
    } else throw error
  }, [shiftData, error, t])

  return (
    <FlexPage appName="Cockpit" title={t('manageShifts.pageName')}>
      <Grid item xs={12} sx={{height: '100%', overflowY: 'auto'}}>
        <Paper
          sx={(theme) => ({
            minHeight: '100%',
            '& .MuiTableHead-root': {
              backgroundColor: theme.palette.grey[200],
              '& .MuiTableCell-root': {
                padding: theme.spacing(1, 2),
                color: theme.palette.grey[600],
                fontSize: '12px'
              }
            },
            '& .MuiTableCell-root': {
              textAlign: 'center'
            },
            '& .MuiInputBase-root': {
              width: '100%'
            },
            '& .MuiFilledInput-input': {
              padding: '15px'
            },
            '& .MuiInputBase-input': {
              width: 'unset'
            }
          })}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 1.25
              }}
            >
              <Typography>{t('manageShifts.editNote')}</Typography>
            </Box>
            <Box
              sx={{
                padding: '0 10px 10px 10px',
                '&:empty': {
                  padding: 'unset'
                },
                display: 'flex',
                justifyContent: 'space-between'
              }}
              data-test-id="shift-action-bar"
            >
              {isEditMode && (
                <>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setShiftList((prevShifts: ShiftInput[]): ShiftInput[] => {
                        return [...prevShifts, cloneDeep(defaultRow)]
                      })
                    }}
                    startIcon={<Add />}
                    data-test-id="add-shift-btn"
                  >
                    {t('manageShifts.action.addShift')}
                  </Button>
                  <span>
                    <LoadingButton
                      loading={loading}
                      type="submit"
                      color="primary"
                      variant="contained"
                      data-test-id="save-shifts-btn"
                      startIcon={<Check />}
                      sx={{
                        marginRight: 1.25,
                        '& .MuiButton-outlined': {
                          paddingTop: 1.5
                        }
                      }}
                    >
                      {t('action.save')}
                    </LoadingButton>
                    <Button
                      color="secondary"
                      onClick={() => {
                        setShiftList([{...defaultRow}])
                      }}
                      startIcon={<Close />}
                      data-test-id="cancel-shifts-update-btn"
                    >
                      {t('action.cancel')}
                    </Button>
                  </span>
                </>
              )}
            </Box>

            <ManageShiftTable
              isEditMode={isEditMode}
              shiftList={shiftList}
              setShiftList={setShiftList}
            />
          </form>
        </Paper>
      </Grid>
      <Dialog
        open={submitState.submit}
        onClose={() => {
          if (submitState.success) {
            window.location.reload()
          }
          setSubmitState(initialSubmitState)
        }}
      >
        <Box p={2.5}>
          {submitState.success && (
            <div data-test-id="success-dialog">{t('manageShifts.success.submitted')}</div>
          )}
          {submitState.error?.length && (
            <Box data-test-id="error-dialog">
              <Typography color={'error.main'}>Errors:</Typography>
              {submitState.error?.map((msg) => (
                <Typography color={'error.main'} key={msg}>
                  {msg}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Dialog>
    </FlexPage>
  )
}
