import moment from 'moment-timezone'

import {IsoWeekDay, isoWeekDays} from '../types'

export const shiftWeekDayStart = (
  firstDayOfWeekIndex: number,
  weekDays: IsoWeekDay[]
): IsoWeekDay[] => {
  return [...weekDays.slice(firstDayOfWeekIndex), ...weekDays.slice(0, firstDayOfWeekIndex)]
}

export const formatWeekDay = (day: IsoWeekDay, locale: string): string =>
  moment()
    .locale(locale)
    .days((isoWeekDays.indexOf(day) + 1) % 7)
    .format('ddd')

export const getLocaleDependentWeekDays = (locale: string): IsoWeekDay[] => {
  // 0 = Sunday, 6 = Saturday
  const firstDayOfWeek = moment.localeData(locale).firstDayOfWeek()
  // convert to isWeekDays index
  const firstDayOfWeekIndex = (firstDayOfWeek + 6) % 7
  return shiftWeekDayStart(firstDayOfWeekIndex, [...isoWeekDays])
}

export const sortWeekDaysLocaleDependent = (locale: string, weekDays: IsoWeekDay[]) =>
  getLocaleDependentWeekDays(locale).filter((day) => weekDays.includes(day))
