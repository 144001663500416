import {mapRunningTime, mapRunningTimeDto} from '@hconnect/common/mappers'
import {Stoppage, StoppageDto} from '@hconnect/common/types'
import {omit} from 'lodash'
import moment from 'moment-timezone'

import {isStoppage, isStoppageDto, isTask, isTaskDto} from '../common/utils/eventType'
import {ShiftEvent, ShiftEventDto} from '../types/shiftHandover.types'

// eslint-disable-next-line complexity
export const mapShiftEvent = (response: ShiftEventDto, timezone: string): ShiftEvent => {
  if (!response) return response
  const baseData = {
    created: moment.utc(response.created).tz(timezone),
    lastModified: moment.utc(response.lastModified).tz(timezone),
    lastModifiedStatus: response.lastModifiedStatus
      ? moment.utc(response.lastModifiedStatus).tz(timezone)
      : undefined,
    lastMaintenanceNotificationCreationStart: response.lastMaintenanceNotificationCreationStart
      ? moment.utc(response.lastMaintenanceNotificationCreationStart).tz(timezone)
      : undefined
  }
  if (isStoppageDto(response)) {
    return {
      ...response,
      ...baseData,
      stoppageStart: moment.utc(response.stoppageStart).tz(timezone),
      stoppageEnd: response.stoppageEnd ? moment.utc(response.stoppageEnd).tz(timezone) : undefined,
      pxTrendStoppage:
        response.pxTrendStoppage && mapRunningTime(response.pxTrendStoppage, timezone)
    }
  }

  if (isTaskDto(response)) {
    return {
      ...omit(response, ['assignees']),
      ...baseData,
      dueDate: moment.utc(response.dueDate).tz(timezone),
      doNotStartBefore: response.doNotStartBefore
        ? moment.utc(response.doNotStartBefore).tz(timezone)
        : undefined,
      ...('assignees' in response && response.assignees
        ? {assignees: response.assignees}
        : (undefined as never)),
      ...(response.repetitionInfo && {
        repetitionInfo: {
          ...response.repetitionInfo,
          ...('startDate' in response.repetitionInfo
            ? {
                startDate: moment.utc(response.repetitionInfo.startDate).tz(timezone),
                ...(response.repetitionInfo.endDate && {
                  endDate: moment.utc(response.repetitionInfo.endDate).tz(timezone)
                })
              }
            : {})
        }
      })
    }
  }
  return {
    ...response,
    ...baseData,
    schedule: response.schedule
      ? {
          startDate: moment.utc(response.schedule.startDate).tz(timezone),
          endDate: moment.utc(response.schedule.endDate).tz(timezone)
        }
      : undefined
  }
}

export const mapPartialStoppage = (
  response: Partial<StoppageDto>,
  timezone: string
): Partial<Stoppage> => {
  return {
    ...response,
    stoppageStart: moment.utc(response.stoppageStart).tz(timezone),
    stoppageEnd: response.stoppageEnd ? moment.utc(response.stoppageEnd).tz(timezone) : undefined,
    pxTrendStoppage: response.pxTrendStoppage && mapRunningTime(response.pxTrendStoppage, timezone)
  }
}

export const mapShiftEventDto = (response: ShiftEvent): ShiftEventDto => {
  const baseData = {
    created: response.created.toISOString(),
    lastModified: response.lastModified.toISOString(),
    lastModifiedStatus: response.lastModifiedStatus
      ? response.lastModifiedStatus.toISOString()
      : undefined,
    lastMaintenanceNotificationCreationStart: response.lastMaintenanceNotificationCreationStart
      ? response.lastMaintenanceNotificationCreationStart.toISOString()
      : undefined
  }
  if (isStoppage(response)) {
    return {
      ...response,
      ...baseData,
      stoppageStart: response.stoppageStart.toISOString(),
      stoppageEnd: response.stoppageEnd ? response.stoppageEnd.toISOString() : undefined,
      pxTrendStoppage: response.pxTrendStoppage && mapRunningTimeDto(response.pxTrendStoppage)
    }
  }

  if (isTask(response)) {
    return {
      ...omit(response, ['assignees']),
      ...baseData,
      dueDate: response.dueDate.toISOString(),
      doNotStartBefore: response.doNotStartBefore
        ? response.doNotStartBefore.toISOString()
        : undefined,
      ...('assignees' in response && response.assignees
        ? {assignees: response.assignees}
        : (undefined as never)),
      ...(response.repetitionInfo && {
        repetitionInfo: {
          ...response.repetitionInfo,
          ...('startDate' in response.repetitionInfo
            ? {
                startDate: response.repetitionInfo.startDate.toISOString(),
                ...(response.repetitionInfo.endDate && {
                  endDate: response.repetitionInfo.endDate.toISOString()
                })
              }
            : {})
        }
      })
    }
  }
  return {
    ...response,
    ...baseData,
    schedule: response.schedule?.endDate
      ? {
          startDate: response.schedule.startDate.toISOString(),
          endDate: response.schedule.endDate.toISOString()
        }
      : undefined
  }
}
