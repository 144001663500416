import {ShiftInput} from '@hconnect/common/types/shift.types'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Checkbox,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import React, {Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'react-i18next'

import {IsoWeekDay, isoWeekDays} from '../types/atomic.types'

const properties = {
  name: 'name',
  start: 'start',
  end: 'end',
  weekDays: 'weekDays'
}

type SetValKeys = keyof Omit<ShiftInput, 'weekDays'>

interface ManageShiftTableProps {
  shiftList: ShiftInput[]
  isEditMode?: boolean
  setShiftList: Dispatch<SetStateAction<ShiftInput[]>>
}

export const ManageShiftTable: React.FC<ManageShiftTableProps> = ({
  shiftList,
  isEditMode = false,
  setShiftList
}) => {
  const {t} = useTranslation()

  const setVal = (property: SetValKeys, index: number, value: string): void => {
    setShiftList((prevShifts: ShiftInput[]) => {
      prevShifts[index][property] = value
      return [...prevShifts]
    })
  }

  const setWeekday = (index: number, value: boolean, day: IsoWeekDay): void => {
    setShiftList((prevShifts: ShiftInput[]) => {
      if (value) {
        prevShifts[index][properties.weekDays].push(day)
      } else {
        prevShifts[index][properties.weekDays] = prevShifts[index][properties.weekDays].filter(
          (curr) => curr !== day
        )
      }
      return [...prevShifts]
    })
  }
  return (
    <TableContainer data-test-id="shifts-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('manageShifts.label.shiftName')}</TableCell>
            <TableCell>{t('manageShifts.label.start')}</TableCell>
            <TableCell>{t('manageShifts.label.end')}</TableCell>
            {isoWeekDays.map((day) => (
              <TableCell key={day}>{t(`datePicker.weekdaysShort.${day}`)}</TableCell>
            ))}
            <TableCell>{''}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shiftList.map((currShift, index) => {
            const {name, start, end, weekDays = []} = currShift
            return (
              <TableRow key={index}>
                <TableCell>
                  <Input
                    name={`${index}-${properties.name}`}
                    onChange={(e) => {
                      const value = e.target.value
                      setVal(properties.name as SetValKeys, index, value)
                    }}
                    value={name}
                    disabled={!isEditMode}
                    data-test-id={`shifts-${properties.name}-${index}`}
                    inputProps={{'data-test-id': `shifts-${properties.name}-input-${index}`}}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="time"
                    value={start}
                    name={`${index}-${properties.start}`}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 300 // 5 min
                    }}
                    onChange={(e) => {
                      const value = e.target.value
                      setVal(properties.start as SetValKeys, index, value)
                    }}
                    disabled={!isEditMode}
                    data-test-id={`shifts-${properties.start}-${index}`}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="time"
                    value={end}
                    name={`${index}-${properties.end}`}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 300 // 5 min
                    }}
                    onChange={(e) => {
                      const value = e.target.value
                      setVal(properties.end as SetValKeys, index, value)
                    }}
                    disabled={!isEditMode}
                    data-test-id={`shifts-${properties.end}-${index}`}
                  />
                </TableCell>
                {isoWeekDays.map((day) => {
                  return (
                    <TableCell key={day + index}>
                      <Checkbox
                        key={day + index}
                        name={`${index}-${day}`}
                        color={'primary'}
                        checked={weekDays.findIndex((wd) => wd === day.toLowerCase()) > -1}
                        onChange={(e) => {
                          const selected = e.target.checked
                          setWeekday(index, selected, day)
                        }}
                        disabled={!isEditMode}
                        data-test-id={`shifts-${day}-${index}`}
                      />
                    </TableCell>
                  )
                })}
                <TableCell>
                  {isEditMode && (
                    <Button
                      color="secondary"
                      onClick={() => {
                        setShiftList((prevShifts) => {
                          prevShifts.splice(index, 1)
                          return [...prevShifts]
                        })
                      }}
                      startIcon={<CloseIcon />}
                    >
                      {t('manageShifts.action.remove')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
