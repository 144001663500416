import {MaintenanceNotification, StoppageType} from '@hconnect/common/types'
import {isStoppageTypeWithCode, isStoppageTypeWithReason} from '@hconnect/common/utils'
import {StoppageCodesFilter, StoppageTypeAbbreviation} from '@hconnect/common/utils/stoppageHelper'
import {isEmpty, isString} from 'lodash'

const TYPE_ABBREVIATION_LENGTH = 2
const ABBR_STOPPAGE_TYPE_MAP: Record<StoppageTypeAbbreviation, StoppageType> = {
  cm: 'cementMill',
  rm: 'rawMill',
  co: 'coalMill',
  cr: 'crusher',
  ki: 'kiln',
  rp: 'rollerPress',
  vm: 'verticalMill',
  bm: 'ballMill',
  de: 'dosingEquipment',
  af: 'alternativeFuelInstallation',
  bp: 'bypass',
  ot: 'other'
}

export const isStoppageType = (stoppageType: unknown): stoppageType is StoppageType =>
  isString(stoppageType) &&
  (isStoppageTypeWithReason(stoppageType as StoppageType) ||
    isStoppageTypeWithCode(stoppageType as StoppageType))

export const fromStoppageCodeSearchParam = (
  searchParam: string
): {type: StoppageType; code: string} | undefined => {
  const type = ABBR_STOPPAGE_TYPE_MAP[searchParam.substring(0, TYPE_ABBREVIATION_LENGTH)]
  const code = searchParam.substring(TYPE_ABBREVIATION_LENGTH)
  return isStoppageType(type) && !isEmpty(code)
    ? {
        type,
        code
      }
    : undefined
}

export const maintenanceNotificationSearchParamsToFilterParams = (
  searchParams?: MaintenanceNotification[]
) => {
  if (!searchParams || searchParams.length === 0) {
    return undefined
  }

  if (searchParams.length === 2) {
    return undefined
  }

  return searchParams[0] === 'withNotifications'
}

export const stoppageCodeSearchParamsToFilterParams = (
  searchParams?: string[]
): StoppageCodesFilter[] | undefined => {
  if (!searchParams) {
    return undefined
  }
  const filterValues = searchParams?.map(fromStoppageCodeSearchParam).filter(Boolean) as {
    type: StoppageType
    code: string
  }[]

  return filterValues.reduce((prev, cur) => {
    const {type, code} = cur
    const existingEntryIndex = prev.findIndex((filterValue) => filterValue.type === type)
    if (existingEntryIndex < 0) {
      const filter: StoppageCodesFilter = {type, codes: [code]}
      return [...prev, filter]
    }
    prev[existingEntryIndex].codes.push(code)
    return prev
  }, [] as StoppageCodesFilter[])
}
