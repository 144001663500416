import {getKeyByValue} from '../common/utils/objectUtils'
import {SortOrder} from '../types/shiftHandover.types'

const sortMapping: {
  [key in SortOrder]: 'asc' | 'desc'
} = {
  [SortOrder.Ascending]: 'asc',
  [SortOrder.Descending]: 'desc'
}

export const getSortDirection = (sortOrder?: SortOrder) => {
  return sortOrder ? sortMapping[sortOrder] : undefined
}

export const getSortOrder = (sortDir?: 'asc' | 'desc'): SortOrder | undefined => {
  return sortDir ? getKeyByValue<SortOrder, 'asc' | 'desc'>(sortMapping, sortDir) : undefined
}
