import {isArray} from 'lodash'

export const preparePayload = <T>(payload: T) => {
  const params = {}

  for (const [key, value] of Object.entries(payload)) {
    if (isArray(value)) {
      value?.forEach((param, index) => {
        params[`${key}[${index}]`] = param
      })
    } else {
      params[key] = value
    }
  }
  return params
}
