import {TFunction} from 'i18next'
import {RegisterOptions} from 'react-hook-form'

type Validator<T extends unknown[]> = (t: TFunction, ...param: T) => RegisterOptions
type ValidatorFunction<T> = (value: T) => string | boolean

/**
 * React-hooks-forms validator for use in a `register` call.  Preconfigured for `min` value validation
 */
export const minValidator = (t: TFunction, value: number) => ({
  value,
  message: t('error.minValue', {value})
})
/**
 * React-hooks-forms validator for use in a `register` call.  Preconfigured for `max` value validation
 */
export const maxValidator = (t: TFunction, value: number) => ({
  value,
  message: t('error.maxValue', {value})
})

export const rangeValidator: Validator<[range: [min: number, max: number]]> = (t, [min, max]) => ({
  min: minValidator(t, min),
  max: maxValidator(t, max)
})

export const requiredValidator: Validator<[]> = (t) =>
  ({
    required: t('error.required')
  }) as RegisterOptions

export const uniqueTitleValidator =
  (t: TFunction, existingNames: string[]): ValidatorFunction<string> =>
  (value) => {
    if (existingNames.includes(value)) {
      return t('error.kpiAlreadyExists')
    }
    return true
  }
